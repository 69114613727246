.main-header {
	.inner {
		width: 100%;
		max-width: 1200px;
		height: 80px;
		margin: 0 auto;
		padding: 0 1.5rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	img {
		width: 40px;
		user-select: none;
		pointer-events: none;
	}

	#language-selector {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	
		button {
			color: #aaa;
			width: 33px;
			height: 33px;
			border: 0;
			margin: 0 3px;
			border-radius: 50%;
			position: relative;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background: #fff;
	
			&.active {
				color: #000;
			}
	
			&:after {
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: 0.12s;
				transform: scale(0.5);
				position: absolute;
				border-radius: 50%;
				background: rgba(0, 0, 0, 0.075);
			}
	
			&:hover {
				color: #000;
	
				&:after {
					opacity: 1;
					transform: scale(1.0);
				}
			}
		}
	
		.lang-separator {
			user-select: none;
			pointer-events: none;
			display: inline-block;
		}
	}
}





.content-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem 0 1rem 0;
	position: relative;
	//background-color: rgba(0, 0, 100, 0.2);

	.inner {
		width: 100%;
		max-width: 820px;
		position: relative;
		padding: 0 1.5rem 1.0rem 1.5rem;

		&:after {
			content: '';
			left: 1.5rem;
			bottom: 0;
			width: 173px;
			height: 2px;
			display: block;
			position: absolute;
			background: $c_blk_main;
		}
	}


	h1 {
		text-align: left;
		font-weight: 400;
		line-height: 1.25;
		margin-top: 0;
		margin-bottom: 1.0rem;
		font-size: calc(1.8rem + 2vmin);
	}

	h2 {
		margin-top: 0;
		font-size: 1.2rem;
	}
	

	.project-meta {
		font-size: 0.95rem;
		margin: 1.0rem 0 1.5rem 0;

		span {
			&.separator {
				margin: 0 0.8rem;
				position: relative;
				display: inline-block;
			}
			&.expiration-date {
				opacity: 0.5;
				margin-left: 0.8rem;
				display: inline-block;
				font-style: italic;
			}
		}

		@include xs {
			margin-top: 0.5rem;

			span {
				display: block;
				margin-left: 0;
			}
		}
	}

}