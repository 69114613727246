
@font-face {
	font-display: swap;
	font-weight: normal;
  	font-family: "Monument-Grotesk";
  	src: url('../fonts/monument-grotesk-regular.woff') format('woff');
}


h1, h2, h3, h4 {
	font-weight: 400;
}
