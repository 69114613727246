html {
	margin: 0;
	padding: 0;
	font-size: 16px;
}
body {
	margin: 0;
	color: $c_blk_main;
	letter-spacing: 0.21px;
	font-family: $font-default, -apple-system, BlinkMacSystemFont, sans-serif;
  	// -webkit-font-smoothing: antialiased;
  	// -moz-osx-font-smoothing: grayscale;
}
* {
	box-sizing: border-box;
}

.App {
	padding: 0;
	background-color: #fff;
}


.text-red {
	color: $c_red;
}
.text-green {
	color: #34aa87;
}


.main-content {
	min-height: 50vh;
	position: relative;

	* {
		margin-left: auto;
		margin-right: auto;
	}
	h2 {
		font-weight: 400;
	}
	p {
		width: 100%;
		max-width: 820px;
		padding: 0 1.5rem;
		font-size: 1.35rem;
		line-height: 1.6;

		@include sm {
			font-size: 1.2rem;
		}

		&.scoring-info {
			font-size: 1.1rem;
			text-align: center;
			margin-top: 4rem;

			.scoring-number {
				display: inline-block;
				word-break: keep-all;
			}

			.scoring-separator {
				display: inline-block;
				margin: 0 1.5rem;
			}

			@include sm {
				max-width: 320px;

				.scoring-separator {
					display: none;
				}
			}
		}
	}
	a {
		padding: 0;
		color: $c_blk_main;

		&:hover {
			text-decoration: none;
		}
	}
	ul {
		width: 100%;
		max-width: 820px;
		padding: 0 1.5rem;
		font-size: 1.35rem;
		list-style: none;
	}


	// Shown when page is loading/fetching data
	.loading {
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
	}
}


