
// COLORS
$c_wht_main: #fff;
$c_blk_main: #231f20;
$c_grey_light: #EFEFEF;
$c_red: #d61236;




// FONT-FAMILIES
$font-default: "Monument-Grotesk", sans-serif;



/*--- MEDIA QUERIES ---*/
// Usage example for this: @include xs {}

$screen-xs-max: 576px;
$screen-sm-max: 768px;
$screen-lg-max: 991px;
$screen-xl-max: 1200px;
$screen-xxl-max: 1920px;


// Small devices
@mixin xs {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}
 
// Medium devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
 
// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}
 
// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

// +1080p devices
@mixin xxl {
    @media (max-width: #{$screen-xxl-max}) {
        @content;
    }
}
