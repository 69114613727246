.form-wrapper {
    width: 100%;
    max-width: 820px;
    padding: 0 1.5rem;
    margin-top: 5rem;
    margin-bottom: 6rem;

    h2 {
        font-size: 1.6rem;
        text-align: center;
        margin-top: 0;
        margin-bottom: 2.5rem;

        @include sm {
            margin-bottom: 1.5rem;
        }
    }


    .form-group {
        position: relative;
        padding-bottom: 4rem;
        margin-bottom: 5rem;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 85%;
            max-width: 400px;
            background-color: $c_blk_main;
        }
    }


    .form-group-radio {
        .radio-button-grid {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .label-container {
                margin: 0;
                width: 55px;
                height: 55px;
                display: flex;
                position: relative;
                cursor: pointer;
                user-select: none;
                align-items: center;
                justify-items: center;

                .label-name {
                    font-size: 2rem;
                    position: relative;
                    margin: -2px auto 0 auto;
                }
        
                .label-checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    user-select: none;
                    transition: 0.12s;
                    border-radius: 50%;
                    transform: scale(0.6);
                    background-color: rgba(0, 0, 0, 0.0);
                    // background-color: rgba(0, 0, 150, 0.2);
        
                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     display: none;
                    //     top: 50%;
                    //     left: 50%;
                    //     width: 50px;
                    //     height: 50px;
                    //     border-radius: 50%;
                    //     background: red;
                    //     transform: translate(-50%, -50%);
                    // }
                }
                  
                
                input {
                    // Hide the browser's default radio button
                    opacity: 0;
                    margin: 0;
                    cursor: pointer;
                    position: absolute;
    
                    &:checked {
                        border: solid red 5px;

                        & ~  .label-name {
                            color: #fff;
                        }

                        & ~ .label-checkmark {
                            transform: scale(1.0);
                            background-color: $c_blk_main;
                            animation: checkmark_anim 0.25s ease-out forwards;

                            @keyframes checkmark_anim {
                                  0% { transform: scale(0.6); }
                                 50% { transform: scale(1.25); }
                                100% { transform: scale(1.0); }
                            }

                            &:after {
                                display: block;
                            }
                        }
                    }
                }

    
                  
                
                &:hover .label-checkmark {
                    transform: scale(1.0);
                    background-color: rgba(0, 0, 0, 0.075);
                }
                
            }


            @include sm {
                gap: 8px;
                flex-wrap: wrap;
                justify-content: center;

                .label-container {
                    width: 45px;
                    height: 45px;

                    .label-name {
                        font-size: 1.6rem;
                    }
                }
            }


        }
    }



    .form-group-text {
        margin-bottom: 2rem;

        .label-name {
            height: 0;
            width: 0;
            outline: 0;
            display: block;
            visibility: hidden;
        }
    
        textarea {
            width: 100%;
            height: auto;
            resize: none;
            font-size: 1.2rem;
            line-height: 1.5;
            border-radius: 0;
            overflow-y: hidden;
            padding: 1.0rem 1.0rem;
            border: 2px solid $c_grey_light;
        }

        &:first-child {
            margin-bottom: 0.5rem;
        }
        &:after {
            display: none;
        }
    }


    .form-group-checkbox {
        margin-top: -5.5rem;
        padding-left: 1.2rem;

        label {
            display: inline-flex;
            flex-direction: row;
            column-gap: 1.0rem;
            position: relative;

            &:hover {
                cursor: pointer;

                &:before {
                    background-color: rgba(0, 0, 0, 0.13);
                }
            }

            &:before {
                content: "";
                width: 20px;
                height: 20px;
                top: 2px;
                left: 0;
                border: 1px solid $c_blk_main;
                background-color: transparent;
                position: absolute;
                display: inline-block;
            }

            input {
                // flex-basis: 30px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:focus ~ span {
                    outline: solid;
                    outline-color: rgba(0, 0, 0, 0.3);
                }

                &:checked ~ span {
                    &:before {
                        display: block;
                    }
                }
            }
            > span {
                flex: 1;
                font-size: 1.1rem;
                line-height: 1.4;
                position: relative;
                padding-left: 2.4rem;
                user-select: none;

                &:before {
                    content: "";
                    width: 5px;
                    height: 11px;
                    top: 3px;
                    left: 7px;
                    border: solid $c_blk_main;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    display: none;
                }
            }
        }
    }



    .button-container {
        input {
            width: 90%;
            max-width: 180px;
            padding: 0.8rem 0.8rem;
            color: #fff;
            font-size: 1.2rem;
            border: 0;
            margin: 0 auto;
            display: block;
            position: relative;
            background-color: #000;

            &:hover {
                background-color: #444;
            }
        }
    }
    


    

    // ERRORS

    .error-message-area {
        margin: 1.0rem 0 1.0rem 0;
        text-align: center;
        position: relative;
        margin-bottom: -0.5rem;

        span {
            color: $c_red;
        }
    }


}