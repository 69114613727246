#endmessage {
    // Hidden by default
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    color: #fff;
    

    // Animatable background
    &:after {
        content: '';
        z-index: 50;
        top: 50%;
        left: 50%;
        width: 100%;
        padding-bottom: 100%;
        position: absolute;
        border-radius: 50%;
        background-color: #000;
        transform: translate(-50%, -50%);
        animation: endmessage_bg_reveal 0.9s ease-in 0s 1 forwards;
    }

    .endmessage-content {
        z-index: 60;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        animation: endmessage_content_reveal 0.75s ease-out 1.0s 1 forwards;


        h2 {
            margin: 0;
            font-size: 3rem;
            font-weight: 400;
            margin-bottom: 1.5rem;
        }

        a {
            color: #fff;
            font-size: 1.15rem;
            position: relative;
            display: inline-block;
            text-decoration: none;
            padding: 0.5rem 1.1rem 0.7rem;

            svg {
                width: 26px;
                fill: #fff;
                position: relative;
                display: inline-block;
                margin-left: 12px;
                transition: 0.18s;
            }

            &:hover {
                color: #000;
                background-color: #fff;
                transition: 0.18s;

                svg {
                    fill: #000;
                    transform: translateX(7px);
                }
            }
        }
    }




    // REVEAL ANIMATIONS

    &.active {
        display: block;

        @keyframes endmessage_bg_reveal {
            0%, 20% { border-radius: 50%; transform: translate(-50%, -50%) scale(0.0); }
                95% { border-radius: 50%; transform: translate(-50%, -50%) scale(3.0); }
               100% { border-radius: 0%;  transform: translate(-50%, -50%) scale(3.0); }
        }

        .endmessage-content {
            @keyframes endmessage_content_reveal {
                  0% { opacity: 0; transform: translateX(-30px); }
                100% { opacity: 1; transform: translateX(0px); }
            }
        }
    }
}